<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            v-if="$can('update', permissionType) && !data.deletedAt"
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-1 ml-1"
            :to="{
              name: `update-${permissionType}`,
              params: { id: $route.params.id },
            }"
          >
            <feather-icon icon="EditIcon" />
            {{ $t("button.update") }}
          </b-button>
          <n-button-delete
            v-if="$can('delete', permissionType) && !data.deletedAt"
            :loading="loading"
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="danger"
            class="mb-1 ml-1"
            @confirm="destroy"
          >
            <feather-icon icon="TrashIcon" />
            {{ $t("button.delete") }}
          </n-button-delete>
          <n-button-restore
            v-if="$can('restore', permissionType) && data.deletedAt"
            :loading="loading"
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="success"
            class="mb-1 ml-1"
            @confirm="restore"
          >
            <feather-icon icon="RepeatIcon" />
            {{ $t("button.restore") }}
          </n-button-restore>
        </b-col>
      </b-row>
      <n-input-view
        :fields="fields"
        v-model="data"
        :initValue="initData"
      ></n-input-view>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mt-1"
            @click="back"
          >
            {{ $t("button.back") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonDelete from "@/components/NButtonDelete";
import NButtonRestore from "@/components/NButtonRestore";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInputView from "@/components/NInputView";
import FormInput from "./formInput";

const DepartmentRepository = Repository.get("department");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonDelete,
    NButtonRestore,
    NFormConfirmation,
    NInputView,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        name: null,
        code: null,
        isEnable: true,
      },
      initData: {},
      loading: false,
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    destroy() {
      this.loading = true;
      DepartmentRepository.destroy(this.$route.params.id)
        .then((response) => {
          this.show();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    restore() {
      this.loading = true;
      DepartmentRepository.restore(this.$route.params.id)
        .then((response) => {
          this.show();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    show() {
      DepartmentRepository.show(this.$route.params.id).then((response) => {
        if (response?.data?.data) {
          this.initData = {
            ...response.data.data,
          };
        }
      });
    },
    back() {
      this.$router.back();
    },
  },
  setup() {
    const fields = [...FormInput];
    const permissionType = "department";

    return { fields, permissionType };
  },
};
</script>
